<template>
  <div class="chat-window-container">
    <div class="chat-window">
      <ul>
        <li
          v-for="message in messages"
          :key="message.id"
          :class="{
            'user-msg': message.sender === 'user',
            'ai-msg': message.sender === 'ai',
          }"
        >
          <div class="user-msg span">
            <!-- Check if there's an imageUrl and display it -->
            <img
              v-if="message.content.imageUrl"
              :src="message.content.imageUrl"
              alt="Uploaded image"
              class="message-image"
            />
            <!-- Continue displaying the text -->
            <span class="message-text">{{ message.content.text }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatWindow",
  props: {
    messages: Array,
  },
  updated() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      const chatWindow = this.$el.querySelector(".chat-window");
      if (chatWindow) {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }
    },
  },
};
</script>

<style scoped>
/* Scrollbar container */
.chat-window::-webkit-scrollbar {
  width: 12px; /* Adjust the width of the scrollbar */
}

/* Scrollbar handle */
.chat-window::-webkit-scrollbar-thumb {
  background: #acabab; /* Handle color */
  border-radius: 6px; /* Handle border radius */
  border: 3px solid transparent; /* Makes the handle's corners slightly rounded */
  background-clip: content-box; /* This ensures the border doesn't overlap the background */
}

/* Scrollbar handle on hover */
.chat-window::-webkit-scrollbar-thumb:hover {
  background: #888; /* Darker shade on hover */
}

/* Scrollbar track */
.chat-window::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 6px; /* Match the handle's border radius */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Adds some shadow inside the track */
}

.message-image {
  max-width: 66%; /* Maximum width relative to the chat window */
  max-height: 90vh; /* Maximum height relative to the viewport height */
  display: block;
  margin-bottom: 10px; /* Adjust the space between the image and text */
  object-fit: contain; /* Ensures the image is scaled to maintain its aspect ratio */
}

.chat-window-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.chat-window {
  padding: 20px;
  background: #ffffff; /* Changed to solid white, assuming you might want it consistent */
  height: calc(
    100vh - 100px
  ); /* Adjust the height based on your input bar height */
  overflow-y: auto;
}

.message-text {
  white-space: pre-line; /* Collapses consecutive spaces and preserves line breaks */
}
.chat-window ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-window li {
  margin-bottom: 10px;
  display: flex;
  width: 66vw; /* Width set to 30% of the viewport width */
}

.user-msg span {
  margin-left: auto;
  background-color: #c0ebff; /* Light pink */
}

.ai-msg span {
  background-color: #d7e1f7; /* Deep pink */
  color: #333333;
}

.chat-window span {
  padding: 10px 15px;
  border-radius: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-block;
}
</style>
