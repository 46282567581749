<template>
  <div class="message-input">
    <!-- Image Upload Button -->
    <label class="upload-button">
      <input
        type="file"
        @change="onFileSelected"
        accept="image/*"
        style="display: none"
      />
      📷
    </label>

    <!-- Text Input with Background Image Preview -->
    <div class="input-with-preview">
      <input
        v-model="newMessage"
        :placeholder="imagePreview ? '' : '请输入信息或粘贴图片'"
        :style="inputStyle"
        @keyup.enter="sendMessage"
        @paste="handlePaste"
      />
      <span v-if="imagePreview" @click="removeImage" class="remove-image"
        >✖</span
      >
    </div>

    <!-- Send Button -->
    <button @click="sendMessage">发送</button>
  </div>
</template>

<script>
export default {
  name: "MessageInput",
  props: {
    uploadingImage: Boolean, // Accepting uploadingImage as a prop
  },
  data() {
    return {
      newMessage: "",
      imagePreview: null, // Add this line
    };
  },
  computed: {
    inputStyle() {
      return this.imagePreview
        ? {
            backgroundImage: `url(${this.imagePreview})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "90px center",
            paddingLeft: "90px",
          }
        : {};
    },
  },

  methods: {
    handlePaste(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (const item of items) {
        if (item.type.indexOf("image") === 0) {
          const file = item.getAsFile();
          this.imagePreview = URL.createObjectURL(file); // Set image preview
          this.$emit("uploadImage", file); // Re-use the existing upload mechanism
        }
      }
    },
    sendMessage() {
      if (this.uploadingImage) {
        alert("图片仍在上传中，请重新发送。");
      } else {
        this.$emit("send", this.newMessage, this.imagePreview);
        this.newMessage = ""; // Clear the text input after sending
        this.imagePreview = null; // Clear the image preview
      }
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      if (!file.type.startsWith("image/")) {
        alert("Please upload an image file.");
        return;
      }
      this.imagePreview = URL.createObjectURL(file); // Set image preview
      this.$emit("uploadImage", file);
    },
    removeImage() {
      this.imagePreview = null; // Remove image preview
    },
  },
};
</script>

<style scoped>
.message-input {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff; /* Changed to solid white to match the overall background */
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.message-input input {
  flex-grow: 1;
  border: none;
  padding: 10px 15px;
  border-radius: 18px;
  margin-right: 10px;
  width: 34.18vw; /* Width set to 34.18% of the viewport width */
}

.message-input button {
  padding: 10px 15px;
  border: none;
  border-radius: 18px;
  background-color: #f8f8f8;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.upload-button {
  cursor: pointer; /* Change the cursor to indicate the label is clickable */
  padding: 5px; /* Add some padding for better visual appearance */
  border-radius: 5px; /* Optional: Add rounded corners to the upload button */
  background-color: #f0f0f0; /* Optional: Change the background color of the upload button */
}

.input-with-preview {
  position: relative;
  flex-grow: 1;
}

.input-with-preview input {
  width: 93%; /* Ensure the input fills the container */
}

.remove-image {
  position: absolute;
  left: 2;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  padding: 0 5px;
}
</style>
