
<template>
  <div id="app">
    <div v-if="!firstMessageSent" class="assist-text">
      欢迎使用HS码/关税查询助手。<br />
      <br />
      请将您的产品图片发给我，或者用语言向我描述您的产品，包括产品的外观、用途和材料。<br />
      【目前只支持中国出口美国的鞋类产品】
    </div>
    <ChatWindow :messages="messages" />
    <MessageInput
      @send="handleSend"
      @uploadImage="handleImageUpload"
      :uploadingImage="uploadingImage"
    />
    <div v-if="imagePreview" class="image-preview">
      <img :src="imagePreview" alt="Image preview" />
      <div v-if="uploadingImage" class="loading">Uploading...</div>
    </div>
  </div>
</template>

<script>
import ChatWindow from "./components/ChatWindow.vue";
import MessageInput from "./components/MessageInput.vue";

export default {
  name: "App",
  components: {
    ChatWindow,
    MessageInput,
  },
  data() {
    return {
      messages: [],
      firstMessageSent: false,
      uploadingImage: false,
      imagePreview: null,
      imageUrl: null,
      sessionId: null,
      eventSource: null, // Add eventSource to the data
      streaming: false,
    };
  },
  mounted() {
    console.log("Component mounted, initializing session.");
    this.initializeSession(); // Initialize session when component mounts
  },
  methods: {
    initializeSession() {
      let sessionId = localStorage.getItem("sessionId");
      if (!sessionId) {
        console.log("No existing session ID found, generating a new one.");
        sessionId = `session-${Date.now()}`; // Simple session ID generation
        localStorage.setItem("sessionId", sessionId);
      }
      this.sessionId = sessionId; // Store sessionId in component's data
    },
    handleSend(text) {
      if (this.streaming === true) {
        alert("AI回复时暂无法发消息，请等AI回复完后再试。");
      } else {
        if (this.uploadingImage === false) {
          console.log("handleSend triggered with text:", text);
          this.firstMessageSent = true;
          if (text.trim() || this.imageUrl) {
            const userMessageContent = {
              text: text.trim(),
              ...(this.imageUrl ? { imageUrl: this.imageUrl } : {}),
              sessionId: this.sessionId,
            };

            this.messages.push({
              sender: "user",
              content: userMessageContent,
              id: Date.now(), // Use a timestamp as a unique identifier
            });

            const placeholderId = Date.now() + 1; // Ensure a unique ID for the placeholder
            console.log("Adding placeholder message with ID:", placeholderId);
            console.log(
              `Sending message with sessionId: ${this.sessionId}`,
              userMessageContent
            );
            this.processMessage(text, this.imageUrl, placeholderId);

            if (!this.eventSource) {
              this.initiateStream();
            }

            this.resetImageState();
          }
        }
      }
    },

    initiateStream() {
      console.log(`Initiating SSE connection for sessionId: ${this.sessionId}`);
      this.eventSource = new EventSource(`/stream/${this.sessionId}`);
      //this.streaming = true;
      this.eventSource.onmessage = (event) => {
        const jsonPart = event.data.replace(/^data: /, ""); // Extract JSON part
        const data = JSON.parse(jsonPart);

        // Check if the last message in the chat is from AI; if so, append the new chunk to it
        if (
          this.messages.length > 0 &&
          this.messages[this.messages.length - 1].sender === "ai" &&
          data.message !== "@$*&"
        ) {
          if (
            this.messages[this.messages.length - 1].content.text ===
            "正在进行下一步处理。"
          ) {
            this.messages.pop();
            this.messages.push({
              sender: "ai",
              content: { text: data.message },
              id: Date.now(),
            });
          } else {
            this.messages[this.messages.length - 1].content.text +=
              data.message;
            this.messages = [...this.messages];
          }
        } else if (data.message === "@$*&") {
          this.messages.push({
            sender: "ai",
            content: { text: "正在进行下一步处理。" },
            id: Date.now(),
          });
        } else {
          this.messages.push({
            sender: "ai",
            content: { text: data.message },
            id: Date.now(),
          });
        }
      };
      this.eventSource.onerror = (error) => {
        console.error("Stream encountered an error:", error);
        this.eventSource.close();
        this.eventSource = null; // Reset the eventSource for potential reconnection
      };
    },

    handleImageUpload(file) {
      console.log("handleImageUpload triggered with file:", file);
      if (!file.type.startsWith("image/")) {
        alert("Please upload an image file.");
        return;
      }
      this.uploadingImage = true;
      this.imagePreview = URL.createObjectURL(file);

      const formData = new FormData();
      formData.append("image", file);
      console.log("contacting endpoint /upload-image");
      fetch("/upload-image", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Image uploaded successfully, URL:", data.imageUrl);
          this.imageUrl = data.imageUrl;
          this.uploadingImage = false;
        })
        .catch(() => {
          alert("Failed to upload image.");
          this.uploadingImage = false;
          this.imagePreview = null;
        });
    },

    processMessage(text, imageUrl, placeholderId) {
      console.log(
        `Sending message with sessionId: ${this.sessionId}`,
        text,
        imageUrl,
        placeholderId
      );

      fetch("/process-message", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          text,
          ...(imageUrl ? { imageUrl } : {}),
          sessionId: this.sessionId,
        }),
      })
        .then((response) => {
          console.log("Received response from /process-message", {
            status: response.status,
            statusText: response.statusText,
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          return response.json();
        })
        .then((data) => {
          console.log("Response JSON parsed", data);
          const index = this.messages.findIndex(
            (msg) => msg.id === placeholderId
          );
          console.log(
            "Replacing placeholder message at index",
            index,
            "with data:",
            data
          );

          if (index !== -1) {
            console.log("Matching message found at index", index);
            this.messages.splice(index, 1, {
              sender: "ai",
              content: { text: data.response },
              id: Date.now(),
            });
          } else {
            console.log(
              "No matching message found for placeholderId",
              placeholderId
            );
          }
        })
        .catch((error) => {
          console.error("Error occurred in processMessage", error);
          const index = this.messages.findIndex(
            (msg) => msg.id === placeholderId
          );
          console.log(
            "Error occurred, replacing placeholder message with error message at index",
            index
          );

          if (index !== -1) {
            console.error("Error at index !== -1.");
          } else {
            console.log(
              "No matching message found for placeholderId (in error handler)",
              placeholderId
            );
          }
        });
    },

    resetImageState() {
      console.log("Resetting image state.");
      this.imagePreview = null;
      this.imageUrl = null;
    },
  },
  beforeUnmount() {
    if (this.eventSource) {
      this.eventSource.close(); // Close the SSE connection when the component is about to unmount
    }
  },
};
</script>

<style>
#app {
  background: #ffffff; /* Changed to solid white */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.assist-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: large; /* You can adjust this size as needed */
  font-weight: bold;
  letter-spacing: normal; /* Standard letter spacing for clarity */
  text-align: center;
  font-family: sans-serif; /* Sans-serif typeface for a friendly feel */
}

/* Additional global styles remain unchanged */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.image-preview {
  text-align: center;
}
.image-preview img {
  max-width: 100px;
  max-height: 100px;
}
.loading {
  margin-top: 10px;
}
</style>
